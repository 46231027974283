export default {
  name: 'mRCScaleMixin',
  data: () => ({
    content: {
      pdfPage: {
        mRCScale: [
          { question: 'Exam Date' },
          { question: 'Shoulder abduction' },
          { question: 'Elbow flexion' },
          { question: 'Wrist flexion' },
          { question: 'Wrist extenstion' },
          { question: 'Thumb abduction' },
          { question: 'Hip flexion' },
          { question: 'Knee extension' },
          { question: 'Ankle dorsiflexion' },
          { question: 'Ankle eversion' },
          { question: 'Ankle inversion' },
          { question: 'Neck extension' },
          { question: 'Shoulder external rotation' },
          { question: 'Knee flexion' },
          { question: 'Ankle plantar flexion' },
          { question: 'Hip extension' },
          { question: 'Hip abduction' },
          { question: 'Hip adduction' },
          { question: 'Elbow extension' },
          { question: 'Neck flexion' }
        ]
      }
    }
  }),
  methods: {
    assignMRCScale(data, date) {
      this.content.pdfPage.mRCScale[0][date] = this.formatDateAbvMonth(data.dateOfMRCScale);
      this.content.pdfPage.mRCScale[1][date] = 
        `Right: ${data.shoulderAbductionRight || '-'}, Left: ${data.shoulderAbductionLeft || '-'}`;
      this.content.pdfPage.mRCScale[2][date] = 
        `Right: ${data.elbowFlexionRight || '-'}, Left: ${data.elbowFlexionLeft || '-'}`;
      this.content.pdfPage.mRCScale[3][date] =
        `Right: ${data.wristFlexionRight|| '-'}, Left: ${data.wristFlexionLeft|| '-'}`;
      this.content.pdfPage.mRCScale[4][date] = 
        `Right: ${data.wristExtensionRight || '-'}, Left: ${data.wristExtensionLeft || '-'}`;
      this.content.pdfPage.mRCScale[5][date] =
        `Right: ${data.thumbAbductionRight || '-'}, Left: ${data.thumbAbductionLeft || '-'}`;
      this.content.pdfPage.mRCScale[6][date] =
        `Right: ${data.hipFlexionRight || '-'}, Left: ${data.hipFlexionLeft || '-'}`;
      this.content.pdfPage.mRCScale[7][date] =
        `Right: ${data.kneeExtensionRight || '-'}, Left: ${data.kneeExtensionLeft || '-'}`;
      this.content.pdfPage.mRCScale[8][date] =
        `Right: ${data.ankleDorsiflexionRight || '-'}, Left: ${data.ankleDorsiflexionLeft || '-'}`;
      this.content.pdfPage.mRCScale[9][date] =
        `Right: ${data.ankleEversionRight || '-'}, Left: ${data.ankleEversionLeft || '-'}`;
      this.content.pdfPage.mRCScale[10][date] =
        `Right: ${data.ankleInversionRight || '-'}, Left: ${data.ankleInversionLeft || '-'}`;
      this.content.pdfPage.mRCScale[11][date] = data.neckExtension || '-';
      this.content.pdfPage.mRCScale[12][date] =
        `Right: ${data.shoulderExternalRotationRight || '-'}, Left: ${data.shoulderExternalRotationLeft || '-'}`;
      this.content.pdfPage.mRCScale[13][date] =
        `Right: ${data.kneeFlexionRight || '-'}, Left: ${data.kneeFlexionLeft || '-'}`;
      this.content.pdfPage.mRCScale[14][date] =
        `Right: ${data.hipExtensionRight || '-'}, Left: ${data.hipExtensionLeft || '-'}`;
      this.content.pdfPage.mRCScale[15][date] =
        `Right: ${data.hipAbductionRight || '-'}, Left: ${data.hipAbductionLeft || '-'}`;
      this.content.pdfPage.mRCScale[16][date] =
        `Right: ${data.hipAdductionRight || '-'}, Left: ${data.hipAdductionLeft || '-'}`;
      this.content.pdfPage.mRCScale[17][date] =
        `Right: ${data.elbowExtensionRight || '-'}, Left: ${data.elbowExtensionLeft || '-'}`;
      this.content.pdfPage.mRCScale[18][date] = data.neckFlexion || '-';
    }
  }
};
