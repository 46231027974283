export default {
  name: 'pulmonaryFunctionTestMixin',
  data: () => ({
    content: {
      pdfPage: {
        pulmonaryFunctionTest: [
          { question: 'Date of Pulmonary Function Test' },
          { question: 'FVC (upright) (L)' },
          { question: 'FVC (upright) % predicted normal' },
          { question: 'FEV1 (upright) (L)' },
          { question: 'FEV1 (upright) % predicted normal' },
          { question: 'FVC (supine) (L)' },
          { question: 'FVC (supine) % predicted normal' },
          { question: 'FEV1 (supine) (L)' },
          { question: 'FEV1 (supine) % predicted normal' },
          { question: 'MIP (PImax)' },
          { question: 'MIP Unit' },
          { question: 'SNIP (upright)' },
          { question: 'SNIP (upright) Unit' },
          { question: 'SNIP (supine)' },
          { question: 'SNIP (supine) Unit' },
          { question: 'VC (upright) (L)' },
          { question: 'VC (supine) (L)' },
          { question: 'Notes' }
        ]
      }
    }
  }),
  methods: {
    assignPulmonaryFunctionTest(data, date) {
      this.content.pdfPage.pulmonaryFunctionTest[0][date] = this.formatDateAbvMonth(data.dateOfPulmonaryFunctionTest);
      this.content.pdfPage.pulmonaryFunctionTest[1][date] = data.fVCUprightL;
      this.content.pdfPage.pulmonaryFunctionTest[2][date] = data.fVCUprightPredictedNormal;
      this.content.pdfPage.pulmonaryFunctionTest[3][date] = data.fEV1UprightL;
      this.content.pdfPage.pulmonaryFunctionTest[4][date] = data.fEV1UprightPredictedNormal;
      this.content.pdfPage.pulmonaryFunctionTest[5][date] = data.fVCSupineL;
      this.content.pdfPage.pulmonaryFunctionTest[6][date] = data.fVCSupinePredictedNormal;
      this.content.pdfPage.pulmonaryFunctionTest[7][date] = data.fEV1SupineL;
      this.content.pdfPage.pulmonaryFunctionTest[8][date] = data.fEV1SupinePredictedNormal;
      this.content.pdfPage.pulmonaryFunctionTest[9][date] = data.mIPPImax;
      this.content.pdfPage.pulmonaryFunctionTest[10][date] = data.mIPPImaxUnit;
      this.content.pdfPage.pulmonaryFunctionTest[11][date] = data.sNIPUpright;
      this.content.pdfPage.pulmonaryFunctionTest[12][date] = data.sNIPUprightUnit;
      this.content.pdfPage.pulmonaryFunctionTest[13][date] = data.sNIPSupine;
      this.content.pdfPage.pulmonaryFunctionTest[14][date] = data.sNIPSupineUnit;
      this.content.pdfPage.pulmonaryFunctionTest[15][date] = data.vCUprightL;
      this.content.pdfPage.pulmonaryFunctionTest[16][date] = data.vCSupineL;
      this.content.pdfPage.pulmonaryFunctionTest[17][date] = 
        data.comments?.length > -1 && data.comments[0] !== '' ? data.comments.join(", ") : "-";
    }
  }
};
